<template>
  <div>
    <b-container>
      <b-card class="my-4 pb-0">
        <b-row>
          <b-col md="9">
<!--        <div :style="{-->
<!--              'border-radius': '5px',-->
<!--              'background-size': 'cover',-->
<!--              'background-position': 'center',-->
<!--              'background-image':-->
<!--           'url(' + selectesNew.image + ')' }"-->
<!--             class="w-100 h-400px position-relative">-->
<!--          <div class="overlay-card"></div>-->
<!--        </div>-->
        <img :src="newsInfo.featured_image" class="w-100">
        <div>
          <div class="mt-4 header-horizontal-title position-relative">
            <h4 class="font-weight-bold position-relative text-primary py-1">
              {{ newsInfo.title }}
            </h4>
          </div>
          <p class="font-size-18 text-justify mt-3">{{newsInfo.content}}</p>
          <p class="font-size-18 text-justify mt-3">{{newsInfo.excerpt}}</p>
        </div>
            <div class="d-flex justify-content-around align-items-center mt-5">
              <b-button
                  v-if="newsInfo.prev"
                  @click="$router.push({ name: 'newDetails', params: { newsID: newsInfo.prev.id } })"
                  variant="outline-primary" class="py-2 px-4 iq-border-radius-5">
                <span><i class="las la-angle-double-right"></i></span>
                <span>الخبر السابق</span>
              </b-button>
              <b-button v-if="newsInfo.next"
                  @click="$router.push({ name: 'newDetails', params: { newsID: newsInfo.next.id } })"
                  variant="primary" class="py-2 px-4 iq-border-radius-5">
                <span>الخبر التالى</span>
                <span><i class="las la-angle-double-left"></i></span>
              </b-button>
            </div>
        </b-col>
        <b-col md="3" class="mt-4">
          <div>
            <h4 class="text-primary mb-4">الفيديوهات الأكثر انتشارا</h4>
            <div class="border-1" v-for="(item ,key) in mostNewsData" :key="key">
              <router-link :to="{name:'newDetails', params:{newsID: item.id}}">
                <img :src="item.featured_image" class="w-100">
<!--                <section v-if="type !== 'video'" :style="{-->
<!--              'background-size': 'cover',-->
<!--              'background-position': 'center',-->
<!--              'background-image':-->
<!--           'url(' + item.featured_image + ')' }"-->
<!--                         class="w-100 h-250px position-relative cursor-pointer">-->
<!--                  <div class="overlay-image" v-if="type ==='image'">-->
<!--                    +{{ item.media_count }} صوره-->
<!--                  </div>-->
<!--                </section>-->
                <!--                  <iframe style="width: 100%" height="200" src="https://www.youtube-nocookie.com/embed/gEDcKhhxUZU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
                <h5 class="py-2">{{ item.title }}</h5>
                <p>{{item.content.length > 37 ? item.content.substring(0, 37) + '...' : item.content}}</p>
                <p>{{item.excerpt.length > 37 ? item.excerpt.substring(0, 37) + '...' : item.excerpt}}</p>

              </router-link>
<!--              <div>-->
<!--                <video controls class="w-100 bg-warning-light" height="180" id="myVideo">-->
<!--                  <source src="https://ibbil.dafa.dev/videos/12.mp4" type="video/mp4">-->
<!--                </video>-->
<!--                <h5>الحفل الختامي لمهرجان الملك عبدالعزيز</h5>-->
<!--                <p>-->
<!--                  صصة بتقديم كافة الخدمات المتعلقة بالإبل في المملكة العربية السعودية، وتشمل خدمات التأمين والنقل والتصريح،-->
<!--                </p>-->
<!--              </div>-->
<!--              <div>-->
<!--                <video controls class="w-100 bg-warning-light" height="180" id="myVideo">-->
<!--                  <source src="https://ibbil.dafa.dev/videos/7.mp4" type="video/mp4">-->
<!--                </video>-->
<!--                <h5>الحفل الختامي لمهرجان الملك عبدالعزيز</h5>-->
<!--                <p>-->
<!--                  صصة بتقديم كافة الخدمات المتعلقة بالإبل في المملكة العربية السعودية، وتشمل خدمات التأمين والنقل والتصريح،-->
<!--                </p>-->
<!--              </div>-->
            </div>
          </div>
        </b-col>
        </b-row>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import { core } from '@/config/pluginInit'
import homeServices from '@/modules/primaryPages/home/services/home'
export default {
  name: 'eventDetails',
  mounted () {
    core.index()
  },
  data () {
    return {
      newsInfo: [],
      mostNewsData: []
      // selectesNew: null
    }
  },
  watch: {
    '$route' () {
      this.newsDetails()
    }
  },
  methods: {
    newsDetails () {
      this.loadingProductInfo = true
      homeServices.newsDetails(this.$route.params.newsID).then(res => {
        this.newsInfo = res.data
      }).finally(() => {
        this.loadingProductInfo = false
      })
    },
    mostNews () {
      this.loadingProductInfo = true
      homeServices.getMostNews().then(res => {
        this.mostNewsData = res.data.data
      }).finally(() => {
        this.loadingProductInfo = false
      })
    }
  },
  created () {
    this.newsDetails()
    this.mostNews()
    // eslint-disable-next-line eqeqeq
    // var selectesNew = allNews.find(data => data.id == this.$route.params.id)
    // if (selectesNew) {
    //   this.selectesNew = selectesNew
    // }
  }
}
</script>

<style>
.h-400px {
  height: 400px;
}
</style>
